export const EASY_WORDS = [
  'baby',
  'door',
  'banana',
  'finger',
  'fence',
  'big',
  'swimming pool',
  'sun',
  'church',
  'yo-yo',
  'boy',
  'bag',
  'alligator',
  'mouse',
  'birthday',
  'winter',
  'beach',
  'tree',
  'teacher',
  'king',
  'telephone',
  'eye',
  'water',
  'jelly',
  'balloon',
  'toothbrush',
  'pants',
  'mom',
  'body',
  'bike',
  'toilet paper',
  'baseball',
  'pig',
  'lawn mower',
  'fire',
  'school',
  'belt',
  'pajamas',
  'mud',
  'ice cream cone',
  'arm',
  'drums',
  'spider',
  'shark',
  'seashell',
  'computer',
  'grandma',
  'pillow',
  'kite',
  'homework',
  'ladybug',
  'bed',
  'bird',
  'gum',
  'book',
  'dress',
  'queen',
  'puppy',
  'happy',
  'doctor',
  'frog',
  'blanket',
  'popsicle',
  'pen',
  'sandwich',
  'boat',
  'dad',
  'lunchbox',
  'ice',
  'bottle',
  'elbow',
  'penny',
  'broom',
  'dog',
  'rose',
  'picnic',
  'chair',
  'duck',
  'hair',
  'zoo',
  'party',
  'piano',
  'key',
  'apple',
  'chalk',
  'park',
  'clock',
  'pencil',
  'hill',
  'flag',
  'lollipop',
  'candle',
  'flower',
  'basketball',
  'hug',
  'clown',
  'paper',
  'mountain',
  'nose',
  'cow',
  'grown-up',
  'grass',
  'rainbow',
  'hide-and-seek',
  'pocket',
  'grape',
  'cowboy',
  'doll',
  'forehead',
  'football',
  'crayon',
  'desk',
  'TV',
  'bedtime',
  'hopscotch',
  'dump truck',
  'cold',
  'paint',
  'ear',
  'moon',
  'tuba',
  'singer',
  'race',
  'candy',
  'student',
  'day',
  'jump',
  'hurt',
  'laundry',
  'blue',
  'sad',
  'old',
  'guitar',
  'athlete',
  'night',
  'knee',
  'wedding',
  'bat',
  'buy',
  'trash can',
  'freckle',
  'stream',
  'quiet',
  'mop',
  'swing',
  'radio',
  'square',
  'Monday',
  'school bus',
  'poem',
  'scared',
  'draw',
  'type',
  'short',
  'stairs',
  'asleep',
  'motorcycle',
  'lunch',
  'fog',
  'new',
  'straw',
  'push',
  'dirty',
  'girl',
  'helicopter',
  'playground',
  'tiger',
  'tornado',
  'lime',
  'leg',
  'salt',
  'ankle',
  'cake',
  'shoelace',
  'wheelchair',
  'goodbye',
  'worm',
  'eyebrow',
  'lion',
  'pear',
  'talk',
  'glasses',
  'shirt',
  'spoon',
  'box',
  'wind',
  'green',
  'wolf',
  'snow',
  'couch',
  'fix',
  'flashlight',
  'princess',
  'broken',
  'dictionary',
  'plate',
  'neighbor',
  'roller coaster',
  'bridge',
  'mailbox',
  'flower',
  'white',
  'sandcastle',
  'triangle',
  'thunder',
  'monster',
  'long',
  'lizard',
  'cafeteria',
  'music',
  'fire engine',
  'star',
  'cook',
  'tired',
  'slow',
  'outer space',
  'brave',
  'horse',
  'niece',
  'elephant',
  'tractor',
  'elevator',
  'light bulb',
  'broccoli',
  'cough',
  'heart',
  'calculator',
  'stick',
  'cat',
  'tree house',
  'watermelon',
  'chocolate',
  'birds nest',
  'fast',
  'hungry',
  'red',
  'frown',
  'chew',
  'pepper',
  'sick',
];

export const MEDIUM_WORDS = [
  'taxi cab',
  'standing ovation',
  'alarm clock',
  'tool',
  'banana peel',
  'flagpole',
  'money',
  'wallet',
  'ballpoint pen',
  'sunburn',
  'wedding ring',
  'spy',
  'baby-sitter',
  'aunt',
  'acne',
  'bib',
  'puzzle piece',
  'pawn',
  'astronaut',
  'tennis shoes',
  'blue jeans',
  'twig',
  'banister',
  'batteries',
  'doghouse',
  'campsite',
  'plumber',
  'bedbug',
  'throne',
  'tiptoe',
  'log',
  'mute',
  'pogo stick',
  'stoplight',
  'ceiling fan',
  'bedspread',
  'bite',
  'stove',
  'windmill',
  'nightmare',
  'stripe',
  'spring',
  'wristwatch',
  'eat',
  'matchstick',
  'gumball',
  'bobsled',
  'bonnet',
  'flock',
  'sprinkler',
  'living room',
  'laugh',
  'snuggle',
  'sneeze',
  'bud',
  'elf',
  'headache',
  'slam dunk',
  'Internet',
  'saddle',
  'ironing',
  'board',
  'bathroom',
  'scale',
  'kiss',
  'shopping cart',
  'shipwreck',
  'funny',
  'glide',
  'lamp',
  'candlestick',
  'grandfather',
  'rocket',
  'home movies',
  'seesaw',
  'rollerblades',
  'smog',
  'grill',
  'goblin',
  'coach',
  'claw',
  'cloud',
  'shelf',
  'recycle',
  'glue stick',
  'Christmas carolers',
  'front porch',
  'earache',
  'robot',
  'foil',
  'rib',
  'robe',
  'crumb',
  'paperback',
  'hurdle',
  'rattle',
  'fetch',
  'date',
  'iPod',
  'dance',
  'cello',
  'flute',
  'dock',
  'prize',
  'dollar',
  'puppet',
  'brass',
  'firefighter',
  'huddle',
  'easel',
  'pigpen',
  'bunk bed',
  'bowtie',
  'fiddle',
  'dentist',
  'baseboards',
  'letter opener',
  'photographer',
  'magic',
  'Old Spice',
  'monster',
];

export const HARD_WORDS = [
  'whatever',
  'buddy',
  'sip',
  'chicken coop',
  'blur',
  'chime',
  'bleach',
  'clay',
  'blossom',
  'cog',
  'wish',
  'through',
  'whiplash',
  'cot',
  'blueprint',
  'beanstalk',
  'think',
  'cardboard',
  'darts',
  'inn',
  'Zen',
  'crow’s nest',
  'BFF',
  'sheriff',
  'tiptop',
  'dot',
  'bob',
  'garden hose',
  'blimp',
  'dress shirt',
  'reimbursement',
  'capitalism',
  'step-daughter',
  'applause',
  'jig',
  'jade',
  'blunt',
  'application',
  'rag',
  'squint',
  'intern',
  'brainstorm',
  'sling',
  'half',
  'pinch',
  'leak',
  'skating rink',
  'jog',
  'shrink ray',
  'dent',
  'scoundrel',
  'escalator',
  'cell phone charger',
  'sequins',
  'ladder rung',
  'flu',
  'mast',
  'sash',
  'modern',
  'ginger',
  'clockwork',
  'mess',
  'mascot',
  'runt',
  'chain',
  'scar tissue',
  'suntan',
  'pomp',
  'scramble',
  'sentence',
  'first mate',
  'cuff',
  'cuticle',
  'fortnight',
  'riddle',
  'spool',
  'full moon',
  'forever',
  'rut',
  'hem',
  'new',
  'freight train',
  'diver',
  'fringe',
  'humidifier',
  'handwriting',
  'dawn',
  'dimple',
  'gray hairs',
  'hedge',
  'plank',
  'race',
  'publisher',
  'fizz',
  'gem',
  'ditch',
  'wool',
  'plaid',
  'fancy',
  'ebony and ivory',
  'feast',
  'Murphy’s Law',
  'billboard',
  'flush',
  'inconceivable',
  'tide',
  'midsummer',
  'population',
  'my',
  'elm',
  'organ',
  'flannel',
  'hatch',
  'booth',
];

export const CHRIS_RANDOM_WORDS = [
  'horse and buggy',
  'butcher',
  'hide and seek',
  'mountain climber',
  'threesome',
  'sasquatch',
  'tightrope',
  'garage',
  'dime a dozen',
  'blessing in disguise',
  'Beat around the bush',
  'Better late than never',
  'Break a leg',
  'Call it a day',
  'Cutting corners	',
  'Easy does it',
  'out of control	',
  'Hang in there',
  'Hit the sack',
  'rocket science',
  'captain hook',
  'Miss the boat',
  'No pain no gain',
  'On the ball',
  'Pull yourself together',
  'So far so good',
  'Speak of the devil',
  'worldwide',
  'Time flies',
  'Under the weather',
  'partly cloudy',
  'dead on arrival',
  'building bridges',
  'top gun',
  'under seige',
  'face off',
  'spring suit',
  'killer bees',
  'killer whales',
  'sharknado',
  'forest fire',
  'carrot top',
  'bill murray',
  'jerry seinfeld',
  'kramer',
  'george bush',
  'donald trump',
  'dr fauci',
  'coronavirus',
  'stay at home',
  'shelter in place',
  'face mask',
  'hockey puck',
  'basketball hoop',
  'hoola hoop',
  'cannonball',
  'godzilla',
  'cow pie',
  'shepherds pie',
  'pie chart',
  'pokemon',
  'nintendo',
  'computer screen',
  'headphones',
  'microscope',
  'glass ceiling',
  'joyful noise',
  'shrimp pot',
  'barbeque chicken',
  'cheeseburger',
  'space needle',
  'full body workout',
  'yoga',
  'video chat',
  'working from home',
  'cry me a river',
  'homeschool',
  'avatar',
  'titanic',
  'star wars',
  'spider man',
  'the beatles',
  'to kill a mockingbird',
  'black panther',
  'holy cow',
  'goldilocks',
  'luke skywalker',
  'walking on sunshine',
  'until death',
];

export const PEOPLE = [
  'Jesus',
  'Adolf Hitler',
  'Marilyn Monroe',
  'Donald Trump',
  'Albert Enstein',
  'Michael Jackson',
  'Charlie Chaplin',
  'Abraham Lincoln',
  'Julius Ceasar',
  'Joan of Arc',
  'John Lennon',
  'Mother Theresa',
  'John F. Kennedy',
  'Gandhi',
  'Princess Diana',
  'Leonardo Da Vinci',
  'Christopher Columbus',
  'Pablo Picasso',
  'Socrates',
  'J.K. Rowling',
  'Audrey Hepburn',
  'Steven Spielberg',
  'George Washington',
  'Frida Kahlo',
  'Winston Churchill',
  'Leonardo DiCaprio',
  'Jane Austen',
  'Paul McCartney',
  'William Shakespeare',
  'Johnny Depp',
  'Joseph Stalin',
  'Charles Dickens',
  'Elvis Presley',
  'Mark Twain',
  'Barack Obama',
  'Whitney Houston',
  'Michelangelo',
  'Tom Hanks',
  'Ellen Degeneres',
  'Martin Luther',
  'Bob Dylan',
  'John Travolta',
  'Marco Polo',
  'Pocahontas',
  'Britney Spears',
  'Angelina Jolie',
  'Andy Warhol',
  'Prince',
  'Homer Simpson',
  'Cristiano Ronaldo',
  'Che Guevara',
  'Elizabeth Taylor',
  'Martin Luther King',
  'David Beckham',
  'Walt Disney',
  'Kim Jong Il',
  'Tiger Woods',
  'Oprah Winfrey',
  'Malcolm X',
  'Quentin Tarantino',
  'Lady Gaga',
  'Theodore Roosevelt',
  'Jackie Chan',
  'Anne Frank',
  'Louis Armstrong',
  'David Bowie',
  'George Clooney',
  'Yoko Ono',
  'Neil Armstrong',
  'Charles Darwin',
  'Napoleon',
  'Beyoncé',
  'Alfred Hitchcock',
  'Salvador Dali',
  'Marie Antoinette',
  'Osama Bin Laden',
  'Will Smith',
  'Stephen Hawking',
  'The Zodiac Killer',
  'Agatha Christie',
  'Marlon Brando',
  'Edgar Allen Poe',
  'Olivia Newton-John',
  'Stevie Wonder',
  'Isaac Newton',
  'Aretha Franklin',
  'Elton John',
  'Meryl Streep',
  'Jim Morrison',
  'Aristotele',
  'Michael Jordan',
  'Steve Jobs',
  'Coco Chanel',
  'Bill Clinton',
  'Muhammad Ali',
  'Bill Gates',
  'Thomas Jefferson',
  'Karl Marx',
  'Taylor Swift',
  'Alexander Graham Bell',
  'Mark Zuckerberg',
  'Confucius',
  'Benjamin Franklin',
  'Adele',
  'Archimedes',
  'Jennifer Lawrence',
  'Al Pacino',
  'Sigmund Freud',
  'Fidel Castro',
  'Brad Pitt',
  'Justin Bieber',
  'Bob Marley',
  'Rosa Parks',
  'Sitting Bull',
  'Ronald Reagan',
  'Rihanna',
  'Frank Sinatra',
  'Oscar Wilde',
  'Justin Timberlake',
  'James Dean',
  'Bruce Lee',
  'Celine Dion',
  'Kim Kardashian',
  'Dolly Parton',
  'George Bush',
  'Raphael',
  'Shania Twain',
  'Eminem',
  'Leo Tolstoy',
  'Amelia Earhart',
  'Bruce Springsteen',
  'Tom Cruise',
  'Shakira',
  'Stephen King',
  'Hillary Clinton',
  'Kate Winslet',
  'Jay-Z',
  'Dalai Lama',
  'Amy Winehouse',
];

export const CODENAMES_ORIGINAL = [
  'Hollywood',
  'Well',
  'Foot',
  'New York',
  'Spring',
  'Court',
  'Tube',
  'Point',
  'Tablet',
  'Slip',
  'Date',
  'Drill',
  'Lemon',
  'Bell',
  'Screen',
  'Fair',
  'Torch',
  'State',
  'Match',
  'Iron',
  'Block',
  'France',
  'Australia',
  'Limousine',
  'Stream',
  'Glove',
  'Nurse',
  'Leprechaun',
  'Play',
  'Tooth',
  'Arm',
  'Bermuda',
  'Diamond',
  'Whale',
  'Comic',
  'Mammoth',
  'Green',
  'Pass',
  'Missile',
  'Paste',
  'Drop',
  'Pheonix',
  'Marble',
  'Staff',
  'Figure',
  'Park',
  'Centaur',
  'Shadow',
  'Fish',
  'Cotton',
  'Egypt',
  'Theater',
  'Scale',
  'Fall',
  'Track',
  'Force',
  'Dinosaur',
  'Bill',
  'Mine',
  'Turkey',
  'March',
  'Contract',
  'Bridge',
  'Robin',
  'Line',
  'Plate',
  'Band',
  'Fire',
  'Bank',
  'Boom',
  'Cat',
  'Shot',
  'Suit',
  'Chocolate',
  'Roulette',
  'Mercury',
  'Moon',
  'Net',
  'Lawyer',
  'Satellite',
  'Angel',
  'Spider',
  'Germany',
  'Fork',
  'Pitch',
  'King',
  'Crane',
  'Trip',
  'Dog',
  'Conductor',
  'Part',
  'Bugle',
  'Witch',
  'Ketchup',
  'Press',
  'Spine',
  'Worm',
  'Alps',
  'Bond',
  'Pan',
  'Beijing',
  'Racket',
  'Cross',
  'Seal',
  'Aztec',
  'Maple',
  'Parachute',
  'Hotel',
  'Berry',
  'Soldier',
  'Ray',
  'Post',
  'Greece',
  'Square',
  'Mass',
  'Bat',
  'Wave',
  'Car',
  'Smuggler',
  'England',
  'Crash',
  'Tail',
  'Card',
  'Horn',
  'Capital',
  'Fence',
  'Deck',
  'Buffalo',
  'Microscope',
  'Jet',
  'Duck',
  'Ring',
  'Train',
  'Field',
  'Gold',
  'Tick',
  'Check',
  'Queen',
  'Strike',
  'Kangaroo',
  'Spike',
  'Scientist',
  'Engine',
  'Shakespeare',
  'Wind',
  'Kid',
  'Embassy',
  'Robot',
  'Note',
  'Ground',
  'Draft',
  'Ham',
  'War',
  'Mouse',
  'Center',
  'Chick',
  'China',
  'Bolt',
  'Spot',
  'Piano',
  'Pupil',
  'Plot',
  'Lion',
  'Police',
  'Head',
  'Litter',
  'Concert',
  'Mug',
  'Vacuum',
  'Atlantis',
  'Straw',
  'Switch',
  'Skyscraper',
  'Laser',
  'Scuba Diver',
  'Africa',
  'Plastic',
  'Dwarf',
  'Lap',
  'Life',
  'Honey',
  'Horseshoe',
  'Unicorn',
  'Spy',
  'Pants',
  'Wall',
  'Paper',
  'Sound',
  'Ice',
  'Tag',
  'Web',
  'Fan',
  'Orange',
  'Temple',
  'Canada',
  'Scorpion',
  'Undertaker',
  'Mail',
  'Europe',
  'Soul',
  'Apple',
  'Pole',
  'Tap',
  'Mouth',
  'Ambulance',
  'Dress',
  'Ice Cream',
  'Rabbit',
  'Buck',
  'Agent',
  'Sock',
  'Nut',
  'Boot',
  'Ghost',
  'Oil',
  'Superhero',
  'Code',
  'Kiwi',
  'Hospital',
  'Saturn',
  'Film',
  'Button',
  'Snowman',
  'Helicopter',
  'Loch',
  'Ness',
  'Log',
  'Princess',
  'Time',
  'Cook',
  'Revolution',
  'Shoe',
  'Mole',
  'Spell',
  'Grass',
  'Washer',
  'Game',
  'Beat',
  'Hole',
  'Horse',
  'Pirate',
  'Link',
  'Dance',
  'Fly',
  'Pit',
  'Server',
  'School',
  'Lock',
  'Brush',
  'Pool',
  'Star',
  'Jam',
  'Organ',
  'Berlin',
  'Face',
  'Luck',
  'Amazon',
  'Cast',
  'Gas',
  'Club',
  'Sink',
  'Water',
  'Chair',
  'Shark',
  'Jupiter',
  'Copper',
  'Jack',
  'Platypus',
  'Stick',
  'Olive',
  'Grace',
  'Bear',
  'Glass',
  'Row',
  'Pistol',
  'London',
  'Rock',
  'Van',
  'Vet',
  'Beach',
  'Charge',
  'Port',
  'Disease',
  'Palm',
  'Moscow',
  'Pin',
  'Washington',
  'Pyramid',
  'Opera',
  'Casino',
  'Pilot',
  'String',
  'Night',
  'Chest',
  'Yard',
  'Teacher',
  'Pumpkin',
  'Thief',
  'Bark',
  'Bug',
  'Mint',
  'Cycle',
  'Telescope',
  'Calf',
  'Air',
  'Box',
  'Mount',
  'Thumb',
  'Antarctica',
  'Trunk',
  'Snow',
  'Penguin',
  'Root',
  'Bar',
  'File',
  'Hawk',
  'Battery',
  'Compound',
  'Slug',
  'Octopus',
  'Whip',
  'America',
  'Ivory',
  'Pound',
  'Sub',
  'Cliff',
  'Lab',
  'Eagle',
  'Genius',
  'Ship',
  'Dice',
  'Hood',
  'Heart',
  'Novel',
  'Pipe',
  'Himalayas',
  'Crown',
  'Round',
  'India',
  'Needle',
  'Shop',
  'Watch',
  'Lead',
  'Tie',
  'Table',
  'Cell',
  'Cover',
  'Czech',
  'Back',
  'Bomb',
  'Ruler',
  'Forest',
  'Bottle',
  'Space',
  'Hook',
  'Doctor',
  'Ball',
  'Bow',
  'Degree',
  'Rome',
  'Plane',
  'Giant',
  'Nail',
  'Dragon',
  'Stadium',
  'Flute',
  'Carrot',
  'Wake',
  'Fighter',
  'Model',
  'Tokyo',
  'Eye',
  'Mexico',
  'Hand',
  'Swing',
  'Key',
  'Alien',
  'Tower',
  'Poison',
  'Cricket',
  'Cold',
  'Knife',
  'Church',
  'Board',
  'Cloak',
  'Ninja',
  'Olympus',
  'Belt',
  'Light',
  'Death',
  'Stock',
  'Millionaire',
  'Day',
  'Knight',
  'Pie',
  'Bed',
  'Circle',
  'Rose',
  'Change',
  'Cap',
  'Triangle',
];

export const CODENAMES_UNDERCOVER = [];

export const CODENAMES_DUET = [];

// removed the PEOPLE words
export const ALL_WORDS = EASY_WORDS.concat(
  MEDIUM_WORDS.concat(
    HARD_WORDS.concat(CHRIS_RANDOM_WORDS.concat(CODENAMES_ORIGINAL))
  )
);

export const shuffleWords = (wordArray, numberOfWords) => {
  for (let i = wordArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = wordArray[i];
    wordArray[i] = wordArray[j];
    wordArray[j] = temp;
  }

  return wordArray.slice(0, numberOfWords);
};
